import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormRender from "../../../../Fields/FormRender";
import ImportRegistryData from "./ImportRegistryData";

export default function DashboardTimingContainer(props) {
  const { title, quantity, backgroundColor, icon } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const eventResults = useSelector((state) => state.events.eventResults);
  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );
  const [resultsData, setResultsData] = useState(false);
  const [modaliadesRender, setModaliadesRender] = useState(false);
  const [showDetail, setShowDetail] = useState(true);
  const [showControlsAll, setShowControlsAll] = useState(true);
  const [controlsList, setControlsList] = useState(false);

  useEffect(() => {
    console.log("Cambio de resultados");

    let resultsDashboard = {
      totalParticipants: eventResults.length,
      totalFinishers: 0,
      totalPending: 0,
      distancesFinishers: {},
      distances: {},
      controlPoints: {},
      controlPointsList: {},
    };
    eventResults.forEach((item, index) => {
      if (item.pG > 0) {
        resultsDashboard.totalFinishers++;
        resultsDashboard.distancesFinishers[
          "modalidad_" + item.codMod
        ] = resultsDashboard.distancesFinishers["modalidad_" + item.codMod]
          ? resultsDashboard.distancesFinishers["modalidad_" + item.codMod] + 1
          : 1;
        resultsDashboard.distancesFinishers[
          "modalidad_" + item.codMod + "-rama_" + item.gen
        ] = resultsDashboard.distancesFinishers[
          "modalidad_" + item.codMod + "-rama_" + item.gen
        ]
          ? resultsDashboard.distancesFinishers[
              "modalidad_" + item.codMod + "-rama_" + item.gen
            ] + 1
          : 1;
        resultsDashboard.distancesFinishers[
          "modalidad_" +
            item.codMod +
            "-rama_" +
            item.gen +
            "-categoria_" +
            item.codCat
        ] = resultsDashboard.distancesFinishers[
          "modalidad_" +
            item.codMod +
            "-rama_" +
            item.gen +
            "-categoria_" +
            item.codCat
        ]
          ? resultsDashboard.distancesFinishers[
              "modalidad_" +
                item.codMod +
                "-rama_" +
                item.gen +
                "-categoria_" +
                item.codCat
            ] + 1
          : 1;
      }
      resultsDashboard.distances["modalidad_" + item.codMod] = resultsDashboard
        .distances["modalidad_" + item.codMod]
        ? resultsDashboard.distances["modalidad_" + item.codMod] + 1
        : 1;
      resultsDashboard.distances[
        "modalidad_" + item.codMod + "-rama_" + item.gen
      ] = resultsDashboard.distances[
        "modalidad_" + item.codMod + "-rama_" + item.gen
      ]
        ? resultsDashboard.distances[
            "modalidad_" + item.codMod + "-rama_" + item.gen
          ] + 1
        : 1;
      resultsDashboard.distances[
        "modalidad_" +
          item.codMod +
          "-rama_" +
          item.gen +
          "-categoria_" +
          item.codCat
      ] = resultsDashboard.distances[
        "modalidad_" +
          item.codMod +
          "-rama_" +
          item.gen +
          "-categoria_" +
          item.codCat
      ]
        ? resultsDashboard.distances[
            "modalidad_" +
              item.codMod +
              "-rama_" +
              item.gen +
              "-categoria_" +
              item.codCat
          ] + 1
        : 1;

      // para contar salidas totales
      if (item.registroSalida) {
        resultsDashboard.controlPoints[0] = resultsDashboard.controlPoints[0]
          ? resultsDashboard.controlPoints[0] + 1
          : 1;
        resultsDashboard.controlPoints[
          "0-modalidad_" + item.codMod
        ] = resultsDashboard.controlPoints["0-modalidad_" + item.codMod]
          ? resultsDashboard.controlPoints["0-modalidad_" + item.codMod] + 1
          : 1;
      }
      if (item.pG > 0) {
        resultsDashboard.controlPoints[100000] = resultsDashboard
          .controlPoints[100000]
          ? resultsDashboard.controlPoints[100000] + 1
          : 1;
        resultsDashboard.controlPoints[
          "100000-modalidad_" + item.codMod
        ] = resultsDashboard.controlPoints["100000-modalidad_" + item.codMod]
          ? resultsDashboard.controlPoints["100000-modalidad_" + item.codMod] +
            1
          : 1;
      }

      // para registrar conto de puntos de control
      if (item.parciales) {
        item.parciales.forEach((control, indexControl) => {
          resultsDashboard.controlPoints[control.pCat] = resultsDashboard
            .controlPoints[control.pCat]
            ? resultsDashboard.controlPoints[control.pCat] + 1
            : 1;

          resultsDashboard.controlPoints[
            control.pCat + "-modalidad_" + item.codMod
          ] = resultsDashboard.controlPoints[
            control.pCat + "-modalidad_" + item.codMod
          ]
            ? resultsDashboard.controlPoints[
                control.pCat + "-modalidad_" + item.codMod
              ] + 1
            : 1;
        });
      }
      if (item.pG === 0 && item.registroSalida && !item.borrado) {
        resultsDashboard.totalPending++;
      }
      console.log("resultsDashboard", resultsDashboard);
    });

    // renderizar modalidadesRender

    var modalidadesRender1 = [];
    var puntosControlRender1 = [];
    var totalModalidad = 0;
    var totalRama = 0;

    //     // renderizar puntos de control
    //     puntosControlRender1.push(<div>{resultsDashboard.controlPoints["start"]}</div>)
    // for(var i in puntosControl){

    //   puntosControlRender1.push(<div>{resultsDashboard.controlPoints["start"]}</div>)

    // }

    // renderizar modalitades, Rama y Categorías
    for (var i in eventSubEvents) {
      var ramaCat = eventSubEvents[i].ramaCat;
      var ramasCount = Object.keys(ramaCat).length;
      totalModalidad = eventSubEvents[i].cuenta;
      var ramaRender = [];
      for (var e in ramaCat) {
        var Genero = "";
        if (ramaCat[e].rama === "V") {
          Genero = "Varonil";
        } else if (ramaCat[e].rama === "F") {
          Genero = "Femenil";
        } else if (ramaCat[e].rama === "M") {
          Genero = "Mixta";
        }

        var classColumns = "s12 m12 l12";
        if (ramasCount === 2) {
          classColumns = "s12 m6 l6";
        } else if (ramasCount === 3) {
          classColumns = "s12 m4 l4";
        }

        var categorias = ramaCat[e].categorias;
        var categoryRender = [];

        for (var f in categorias) {
          if (!categorias[f].absolutos) {
            var edadesTexto = "";
            console.log(
              resultsDashboard.distances,
              resultsDashboard.distances[i + "-" + e + "-" + f],
              i + "-" + e + "-" + f
            );
            categoryRender.push(
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                key={f + i + Genero}
              >
                <Grid item xs={6} sm={6}>
                  <Typography align="center" variant={"subtitle1"}>
                    {categorias[f].nombreCategoria} {edadesTexto}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography align="center" variant={"subtitle1"}>
                    {
                      cleanCount(
                        resultsDashboard.distancesFinishers[
                          i + "-" + e + "-" + f
                        ],
                        resultsDashboard.distances[i + "-" + e + "-" + f],
                        true
                      ).texto
                    }
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography align="center" variant={"subtitle1"}>
                    {
                      cleanCount(
                        resultsDashboard.distancesFinishers[
                          i + "-" + e + "-" + f
                        ],
                        resultsDashboard.distances[i + "-" + e + "-" + f],
                        true
                      ).textoporcentaje
                    }
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        }
        ramaRender.push(
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              key={f + i + Genero + 1}
            >
              <Grid item xs={12} sm={12}>
                <Paper
                  style={{ backgroundColor: "#e0e0e0", padding: 15, margin: 2 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    key={i}
                  >
                    <Grid item xs={6} sm={6}>
                      <Typography align="center" variant={"h5"} color="primary">
                        Rama {Genero}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Typography align="center" variant={"h5"} color="primary">
                        {
                          cleanCount(
                            resultsDashboard.distancesFinishers[i + "-" + e],
                            resultsDashboard.distances[i + "-" + e],
                            true
                          ).texto
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Typography
                        align="center"
                        variant={"subtitle1"}
                        color="primary"
                      >
                        {
                          cleanCount(
                            resultsDashboard.distancesFinishers[i + "-" + e],
                            resultsDashboard.distances[i + "-" + e],
                            true
                          ).textoporcentaje
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                {categoryRender}
              </Grid>
            </Grid>
          </Grid>
        );
      }
      modalidadesRender1.push(
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          key={i}
        >
          <Grid item xs={12} sm={12}>
            <Paper
              style={{ backgroundColor: "#f44336", padding: 5, margin: 2 }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                key={i}
              >
                <Grid item xs={6} sm={6}>
                  {i === "modalidad_1" && (
                    <Button
                      style={{ padding: 10 }}
                      variant="contained"
                      onClick={() => setShowDetail(!showDetail)}
                    >
                      {showDetail && "Ocultar detalle"}
                      {!showDetail && "Abrir detalle"}
                    </Button>
                  )}

                  <Typography
                    align="center"
                    variant={"h4"}
                    style={{ color: "#ffffff" }}
                  >
                    {eventSubEvents[i].descripcionModalidad}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography
                    align="center"
                    variant={"subtitle1"}
                    style={{ color: "#ffffff" }}
                  >
                    Finishers / Total
                  </Typography>
                  <Typography
                    align="center"
                    variant={"h4"}
                    style={{ color: "#ffffff" }}
                  >
                    {
                      cleanCount(
                        resultsDashboard.distancesFinishers[i],
                        resultsDashboard.distances[i],
                        true
                      ).texto
                    }
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    align="center"
                    variant={"subtitle1"}
                    style={{ color: "#ffffff" }}
                  >
                    Porcentaje
                  </Typography>

                  <Typography
                    align="center"
                    variant={"h4"}
                    style={{ color: "#ffffff" }}
                  >
                    {
                      cleanCount(
                        resultsDashboard.distancesFinishers[i],
                        resultsDashboard.distances[i],
                        true
                      ).textoporcentaje
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {showDetail && ramaRender}
        </Grid>
      );
    }

    setModaliadesRender(modalidadesRender1);
    setResultsData(resultsDashboard);
    getControlsList();
  }, [eventResults, showDetail]);

  const getKM = (pkt, codigoModalidad, distance) => {
    if (
      puntosControl["kPC_" + Math.round(pkt / 1000)] &&
      puntosControl["kPC_" + Math.round(pkt / 1000)]
        .modalidadesPuntoDeControl &&
      puntosControl["kPC_" + Math.round(pkt / 1000)].modalidadesPuntoDeControl[
        "modalidad_" + codigoModalidad
      ] &&
      puntosControl["kPC_" + Math.round(pkt / 1000)].modalidadesPuntoDeControl[
        "modalidad_" + codigoModalidad
      ].laps["lap_" + (pkt % 1000)] &&
      puntosControl["kPC_" + Math.round(pkt / 1000)].modalidadesPuntoDeControl[
        "modalidad_" + codigoModalidad
      ].laps["lap_" + (pkt % 1000)].kilometro
    ) {
      return (
        "km:" +
        puntosControl["kPC_" + Math.round(pkt / 1000)]
          .modalidadesPuntoDeControl["modalidad_" + codigoModalidad].laps[
          "lap_" + (pkt % 1000)
        ].kilometro
      );
    }
    if (pkt === 0) {
      return "km:0";
    } else if (pkt === 100000) {
      return "km:" + distance;
    }
    return "No aplica";
  };
  const getControlsList = () => {
    if (
      eventDetail !== undefined &&
      eventResults !== undefined &&
      puntosControl !== undefined
    ) {
      var valor = [];
      var arrayControls = [];
      // agrego valor null para tener todos los controles
      // agrego valor 0 (CERO) para tener solo la meta
      valor.push({
        value: 100000,
        label: "Meta del evento",
      });

      for (var i in puntosControl) {
        console.log("controlPoint", puntosControl[i].descripcionFinalPunto);
        var descripcionFinalPunto = puntosControl[i].descripcionFinalPunto;
        var modalidadesControl = puntosControl[i].modalidadesPuntoDeControl;
        for (var e in modalidadesControl) {
          var descripcion = modalidadesControl[e].descripcionModalidad;
          var laps = modalidadesControl[e].laps;
          for (var f in laps) {
            var descripcionLap = laps[f].descripcionPunto;
            var controlPoint = puntosControl[i].kNroPunto * 1000 + laps[f].lap;
            console.log("controlPoint", controlPoint);

            if (
              arrayControls.indexOf(controlPoint) === -1 &&
              controlPoint !== 0 &&
              laps[f].tipoControl !== 3 &&
              laps[f].tipoControl !== 1
            ) {
              arrayControls.push(controlPoint);
              console.log(arrayControls);
              valor.push({
                value: controlPoint,
                label: descripcionFinalPunto + " C:" + controlPoint,
              });
            }
          }
        }
      }
    }

    // agrego valor 100000 para pedir datos de los competidores que no han llegado
    valor.push({
      value: 0,
      label: "Salida",
    });

    setControlsList(valor);
  };

  const cleanCount = (dato, total, porcentaje) => {
    const datoNew = dato ? dato : 0;
    var texto = "";
    if (total === undefined) {
      texto = "0 / 0";
    } else {
      texto = datoNew + " / " + total + "";
    }
    var textoporcentaje = "";
    if (porcentaje && dato !== undefined && dato !== 0) {
      textoporcentaje = "(" + Math.round((dato / total) * 100) + " %)";
    }
    return { texto: texto, textoporcentaje: textoporcentaje };
  };

  return (
    <Paper style={{ padding: 20, margin: 5, backgroundColor: backgroundColor }}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={12} sm={12}>
          <Typography gutterBottom variant={"h3"} component="h2">
            DASHBOARD
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {" "}
          <Paper>
            <Typography align="center" variant={"subtitle1"} component="h2">
              Participantes:
            </Typography>
            <Typography
              color="primary"
              align="center"
              variant={"h1"}
              component="h2"
            >
              {resultsData.totalParticipants}
            </Typography>{" "}
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper>
            <Typography align="center" variant={"subtitle1"} component="h2">
              Finishers:{" "}
              {Math.round(
                (resultsData.totalFinishers / resultsData.totalParticipants) *
                  100
              )}{" "}
              %
            </Typography>
            <Typography
              color="primary"
              align="center"
              variant={"h1"}
              component="h2"
            >
              {resultsData.totalFinishers}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          {" "}
          <Paper>
            <Typography align="center" variant={"subtitle1"} component="h2">
              Por terminar:
            </Typography>
            <Typography
              color="primary"
              align="center"
              variant={"h1"}
              component="h2"
            >
              {resultsData.totalPending === 0 ? "NA" : resultsData.totalPending}
            </Typography>{" "}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper
            style={{
              marginTop: 10,
              marginBottom: 10,
              backgroundColor: "#e0e0e0",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" data-field="id">
                    <Button
                      style={{ padding: 10 }}
                      variant="contained"
                      color="primary"
                      onClick={() => setShowControlsAll(!showControlsAll)}
                    >
                      {showControlsAll && "Ocultar modalidades"}
                      {!showControlsAll && "Abrir modalidades"}
                    </Button>
                  </TableCell>

                  {controlsList &&
                    controlsList
                      .sort((a, b) => a.value - b.value)
                      .map((a, i) => (
                        <TableCell
                          key={"title" + i}
                          align="center"
                          data-field="id"
                        >
                          {a.label}
                        </TableCell>
                      ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" data-field="id">
                    Todas
                  </TableCell>

                  {controlsList &&
                    controlsList
                      .sort((a, b) => a.value - b.value)
                      .map((a, index) => (
                        <TableCell key={index} align="center">
                          <Typography
                            color="primary"
                            align="center"
                            variant={"h5"}
                            component="h2"
                          >
                            {resultsData.controlPoints[a.value]
                              ? resultsData.controlPoints[a.value]
                              : 0}
                          </Typography>{" "}
                        </TableCell>
                      ))}
                </TableRow>

                {eventSubEvents &&
                  showControlsAll &&
                  Object.values(eventSubEvents).map((mod, i) => (
                    <TableRow
                      key={i}
                      style={{ backgroundColor: i % 2 ? "" : "white" }}
                    >
                      <TableCell align="center" data-field="id">
                        {mod.descripcionModalidad}
                      </TableCell>

                      {controlsList &&
                        controlsList
                          .sort((a, b) => a.value - b.value)
                          .map((a, index) => (
                            <TableCell key={index} align="center">
                              <Typography
                                align="center"
                                variant={"subtitle1"}
                                component="h2"
                              >
                                {getKM(
                                  a.value,
                                  mod.codigoModalidad,
                                  mod.modalidad
                                )}
                              </Typography>{" "}
                              <Typography
                                color="primary"
                                align="center"
                                variant={"h5"}
                                component="h2"
                              >
                                {resultsData.controlPoints[
                                  a.value + "-modalidad_" + mod.codigoModalidad
                                ]
                                  ? resultsData.controlPoints[
                                      a.value +
                                        "-modalidad_" +
                                        mod.codigoModalidad
                                    ]
                                  : 0}
                              </Typography>{" "}
                            </TableCell>
                          ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12}>
          {modaliadesRender}
        </Grid>
      </Grid>
    </Paper>
  );
}
